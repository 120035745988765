import { ref, onMounted } from 'vue';
export default {
  setup() {
    const onClickLeft = () => history.back();
    onMounted(() => {
      console.log('xx');
    });
    const is_auth = ref(false);
    return {
      onClickLeft,
      is_auth
    };
  }
};